<template>
    <div>
        <h5 class="text-white" style="font-weight: 700;font-size:20px;">Pool Data</h5>
        <div class="p-2 rounded bg-pri">

            <div class="my-5">
                <ChartJsComponent :options="options" :style="styleOptions" @chart-ref="chartInstance" />

            </div>

            <div class="row">
                <div class="col-6 mb-2 ps-2 pe-1">
                    <div class="bg-sec p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/surbodinate.png" style="width: 34px;" alt="">
                        </div>
                        <div>
                            <p class="mb-0 text" style="line-height: 2;"><b>732,136,{{ TotalProduct }}</b></p>
                            <div class="text2">TotalProduct</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-2 ps-1 pe-2">
                    <div class="bg-sec p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/team.png" style="width: 34px;" alt="">
                        </div>
                        <div>
                            <p class="mb-0 text" style="line-height: 2;"><b>10,567, {{ ValidNode }}</b></p>
                            <div class="text2">ValidNode</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 ps-2 pe-1">
                    <div class="bg-sec p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/surbodinate.png" style="width: 34px;" alt="">
                        </div>
                        <div style="word-break: break-all;">
                            <p class="mb-0 text" style="line-height: 1;word-break: break-all;"><b>6,338,992, {{ UserRevenue
                            }}</b></p>
                            <div class="text2">UserRevenue</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 ps-1 pe-2">
                    <div class="bg-sec p-2 rounded d-flex align-items-center">
                        <div class="bg-dark text-center p-2 me-1">
                            <img src="@/assets/team.png" style="width: 34px;" alt="">
                        </div>
                        <div>
                            <p class="mb-0 text" style="line-height: 2;"><b>27,687, {{ Participant }}</b></p>
                            <div class="text2">Participant</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ChartJsComponent from '@/assets/CanvasJsVueComponent.vue'
export default {
    components: { ChartJsComponent },

    data() {
        return {
            TotalProduct: 214,
            ValidNode: 823,
            UserRevenue: 369,
            Participant: 198,


            chart: null,
            options: {
                legend: {
                    fontColor: "#fff"
                },
                fontColor: "#fff",
                backgroundColor: "transparent",
                fillColor: "#fff",
                animationEnabled: true,
                exportEnabled: false,
                theme: "transparent",

                axisX: {
                    valueFormatString: "YYYY",
                    labelTextAlign: "center",
                    labelAngle: 0
                },
                data: [{
                    type: "line",
                    dataPoints: [
                        { label: "07:00", y: 30 },
                        { label: "09:00", y: 26 },
                        { label: "11:00", y: 25 },
                        { label: "13:00", y: 25 },
                        { label: "15:00", y: 27 },
                    ]
                }]
            },
            styleOptions: {
                width: "100%",
                height: "200px",
            }
        }
    },
    chartInstance(chart) {
        this.chart = chart;
    },
    mounted() {
        let date = new Date();
        let value = (date.getYear() + date.getMonth() + date.getDay()) + (((date.getHours() + date.getMinutes() + date.getSeconds()) * 10))
        console.log(value)
        this.TotalProduct += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)
        this.UserRevenue += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)
        this.Participant += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)
        this.ValidNode += value + Math.floor(Math.random() * (10 - 1 + 1) + 1)


    }
}

</script>
<style scoped>
.text {
    color: #ff1c99 !important
}

.text2 {
    color: #7a90d3 !important;
}

.canvasjs-chart-credit {
    display: none !;
}
</style>