import AuthService from '../services/auth.service';
import userService from '@/services/user.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user.data);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    updateUserInfo({ commit }) {
      return userService.getUserInfo().then(
        response => {
          commit('updateUserInfo',response.data)
          return Promise.resolve(response);
        }
      )
    },
    removeUserInfo({ commit }) {
      commit('removeUserInfo')
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state, data) {
      console.log('data')
      console.log(data)
      state.status.loggedIn = true;
      state.user = {
        ...data.data,
        token:data.token
      };
      console.log(state.user)
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    updateUserInfo(state , user) {
      let token = state.user.token
      state.user = {
        ...user,
        token
      }
      // localStorage.setItem('user',JSON.stringify(state.user))
    },
    removeUserInfo(state) {
      state.user = null
    }
  }
};